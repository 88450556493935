<template>
  <div class="data-contain">
    <div class="card-wrapper">
      <div class="monitor-search">
        <div class="monitor-date">
          <Date-picker format="yyyy-MM-dd" type="daterange" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
        </div>
        <div class="tab-contain">
          <router-link class="static-button" :to="{name: 'BloodO2'}" >统计显示</router-link>
          <router-link :to="{name: 'BloodO2Table'}">数据显示</router-link>
          <router-link class="chart-button" :to="{name: 'BloodO2Chart'}">图表显示</router-link>
        </div>
      </div>
    </div>
    <div class="chart" ref="capacity"></div>
  </div>
</template>
<script type="text/ecmascript-6">
import echarts from 'echarts';
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			capacityData: {
				index: 2,
				start: '',
				end: '',
				page: 1,
				size: 10,
			},
			timeData: [],
			capacityList: [],
			Charts: '',
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	methods: {
		handleInit() {
			this.capacityData.member_id = this.memberId;
			this.timeData = [];
			this.capacityList = [];
			MemberService.spoQuery(this.capacityData).then((data) => {
				data.data_show.forEach((item) => {
					this.timeData.unshift(item.create_time);
					this.capacityList.unshift(item.value);
				});
				this.Charts.hideLoading();
				this.Charts.setOption({
					xAxis: {
						data: this.timeData,
					},
					series: [
						{
							// 根据名字对应到相应的系列
							name: '血氧',
							data: this.capacityList,
						},
					],
				});
			});
		},
		handleChangeDate(value) {
			this.capacityData.start = value[0];
			this.capacityData.end = value[1];
			this.handleInit();
		},
	},
	mounted() {
		this.handleInit();
		// echarts实例化
		this.Charts = echarts.init(this.$refs.capacity);
		// 初始化实例
		var options = {
			title: {
				text: '整体趋势',
				textStyle: {
					color: '#424e67',
					fontSize: '16',
				},
				padding: 25,
			},
			backgroundColor: '#f1f2f7',
			grid: {
				bottom: 80,
			},
			color: ['#32d2c9'],
			legend: {
				data: ['血氧'],
				x: 'right',
				orient: 'vertical',
				padding: 25,
				textStyle: {
					color: '#666',
					fontSize: 12,
				},
			},
			xAxis: {
				type: 'category',
				boundaryGap: false,
				axisLine: { onZero: false },
				data: [],
			},
			yAxis: [
				{
					name: 'mL',
					type: 'value',
				},
			],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
					animation: false,
					label: {
						backgroundColor: '#505765',
					},
				},
			},
			dataZoom: [
				{
					show: true,
				},
				{
					type: 'inside',
				},
			],
			series: [
				{
					name: '血氧',
					type: 'line',
					symbolSize: 10,
					animation: false,
					areaStyle: {
						normal: {
							color: 'transparent',
						},
					},
					lineStyle: {
						normal: {
							width: 3,
							color: '#32d2c9',
						},
					},
					itemStyle: {
						normal: {
							lineStyle: {
								color: '#fff',
							},
						},
					},
					data: [],
				},
			],
		};
		this.Charts.setOption(options);
		this.Charts.showLoading();
	},
};
</script>
<style lang="css" scoped>
</style>
